import IDateGroup from "src/components/dateselector/IDateGroup";
import { isSameDay, format } from "date-fns";
import styled, { css } from 'src/lib/styles/css';
import locale from "date-fns/locale/nb";

//#region [Props]
type DateItemProps = {
    month?: IDateGroup;
    date: Date;
    selectedDate: Date;
    changeDate: (date: Date) => void;
};
//#endregion

//#region [Component]
export default function DateItem({ month, date, selectedDate, changeDate }: DateItemProps) {
    const clickable = !!(month?.dates?.find((elem) => isSameDay(elem, date)));
    return (
        <SDateItemButton disabled={!clickable} onClick={() => { changeDate(date) }}>
            <SDateDisplay $isClickable={clickable} $isSelected={isSameDay(date, selectedDate)}>
                {format(date, 'd', { locale })}
            </SDateDisplay>
        </SDateItemButton>
    );
}

//#endregion

//#region [Styles]
const SDateItemButton = styled.button`
	border: none;
	background-color: transparent;
	width: ${100 / 7}%;
	text-align: center;
	font-size: 18px;
	font-weight: normal;
	padding-top: ${100 / 7}%;
	position: relative;
`;

type SDateDisplayProps = {
    $isClickable?: boolean;
    $isSelected?: boolean;
}

const SDateDisplay = styled.div<SDateDisplayProps>`
	position: absolute;
	top: 3px;
	bottom: 3px;
	left: 3px;
	right: 3px;

	color: var(--TEXTCOLOR_OVERRIDE, var(--iconcolor));

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 18px;
	font-weight: bold;

	${props => props.$isClickable ? css`
		border: 2px var(--TEXTCOLOR_OVERRIDE, var(--iconcolor)) solid;
		-moz-outline-radius: 100px;
		border-radius: 100px;
		cursor: pointer;

		&:hover {
			background-color: var(--TEXTCOLOR_OVERRIDE, var(--iconcolor));
			color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
			border-radius: 100px;
			outline: none;
		}
	`: ""}

	${props => props.$isSelected ? css`
		background-color: var(--TEXTCOLOR_OVERRIDE, var(--maincolor));
		color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--iconcolor));
		border-radius: 100px;
		cursor: pointer;
		font-size: 24px;
	` : ""}
`;
//#endregion