import { useProgramDatesContext } from "src/lib/contexts/ProgramDatesWrapper";
import styled, { css } from 'src/lib/styles/css';
import { useEffect, useState } from "react";
import locale from "date-fns/locale/nb";
import { format, startOfMonth, getDay, isSameMonth, isSameDay } from "date-fns";
import SelectorWrapper from "src/components/selectorwrapper/SelectorWrapper";
import theme from "src/components/themes/DefaultTheme";
import LeftArrowSvg from "src/components/svg/LeftArrowSvg";
import RightArrowSvg from "src/components/svg/RightArrowSvg";
import IDateGroup from "src/components/dateselector/IDateGroup";
import DateItems from "src/components/dateselector/DateItems";

//#region [Props]
type DateSelectorProps = {
	className?: string;
};
//#endregion

//#region [Component]
export default function DateSelector({ className }: DateSelectorProps) {
	const selectedDateContext = useProgramDatesContext();

	const [groupedDates, setGroupedDates] = useState<IDateGroup[] | null>(null);
	const [monthIndex, setMonthIndex] = useState(0);

	useEffect(() => {
		if (selectedDateContext.availableDates) {
			const _groupedDates = groupDates(selectedDateContext.availableDates);
			setGroupedDates(_groupedDates);
		}
	}, [selectedDateContext.availableDates]);

	useEffect(() => {
		// only run this the first time groupedDates is available
		if (groupedDates) {
			const idx = groupedDates.findIndex(gd => gd.dates.some(d => isSameDay(selectedDateContext.selectedDate!, d)));
			if (idx > 0) {
				setMonthIndex(idx);
			}

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupedDates]);

	if (!groupedDates) {
		return null;
	}

	return <SelectorWrapper
		onClose={() => selectedDateContext.setShowSelectDateDialog(false)}
		heading="Velg dag">
		<div>
			<SMonthWrapper>
				<SArrowButton onClick={() => setMonthIndex(monthIndex - 1)} disabled={monthIndex === 0}>
					<SLeftArrowSvg />
				</SArrowButton>
				<SMonth>{getMonthString(groupedDates[monthIndex]?.month)}</SMonth>
				<SArrowButton onClick={() => setMonthIndex(monthIndex + 1)} disabled={monthIndex >= groupedDates.length - 1}>
					<SRightArrowSvg />
				</SArrowButton>
			</SMonthWrapper>
			<DateItems
				month={groupedDates?.[monthIndex]}
				changeDate={(date: Date) => { selectedDateContext.setSelectedDate(date); selectedDateContext.setShowSelectDateDialog(false); }}
				selectedDate={selectedDateContext.selectedDate!} />
		</div>
	</SelectorWrapper>;
}
//#endregion

//#region [Styles]
const SMonthWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		padding-top: 80px;
	}
`;

const SArrowButton = styled.button`
	border: none;
	background-color: transparent;
	flex: 0 0 auto;
	padding-top: 7px;
	opacity: 1;
	transition: opacity 0.2s;

	&[disabled] {
		opacity: 0;
	}
`;

const CSSArrowSvg = css`
	width: 30px;
	path {
		fill: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
	}
`;

const SLeftArrowSvg = styled(LeftArrowSvg)`
	${CSSArrowSvg}
`;

const SRightArrowSvg = styled(RightArrowSvg)`
	${CSSArrowSvg}
`;

const SMonth = styled.div`
	flex: 0 0 auto;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
	min-width: 50%;
	text-transform: capitalize;
`;
//#endregion

//#region [Other]

function getMonthString(month: Date | undefined | null) {
	if (!month) return "";
	return format(month, "MMMM yyyy", { locale });
}

function getMonthFirstDay(month: Date) {
	const som = getDay(startOfMonth(month)) - 1;
	return som < 0 ? 6 : som;
}

function groupDates(dates: Date[] | null | undefined): IDateGroup[] {
	let prevDate: Date | null = null;
	let currMonth: IDateGroup | null = null;
	const groupedDates: IDateGroup[] = [];

	dates?.forEach(d => {
		if (!prevDate || !isSameMonth(d, prevDate)) {
			prevDate = d;
			currMonth = { month: d, dates: [d] };
			groupedDates.push(currMonth);
		} else {
			currMonth?.dates.push(d);
		}
	});

	return groupedDates;
}

//#endregion