import { StandardSvgProps } from "src/components/svg/StandardSvgProps";
import { SDefaultSvg } from "src/components/svg/SvgStyles";
import styled from 'src/lib/styles/css';

//#region [Props]
type LeftArrowSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function LeftArrowSvg({ className }: LeftArrowSvgProps) {
	return <SDefaultSvg className={className} viewBox="0 0 11.67 19.799999">
		<path d="M 11.67,1.77 9.9,0 0,9.9 9.9,19.8 11.67,18.03 3.54,9.9 Z" />
	</SDefaultSvg>;
}
//#endregion

//#region [Styles]

//#endregion