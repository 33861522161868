import Button from "src/components/button/Button";
import LocationSvg from "src/components/svg/LocationSvg";
import { useLocationContext } from "src/lib/contexts/LocationContext";

//#region [Props]
type LocationSelectorButtonProps = {
	className?: string;
	useStaticText?: boolean;
};
//#endregion

//#region [Component]
export default function LocationSelectorButton({ className, useStaticText }: LocationSelectorButtonProps) {
	const locationContext = useLocationContext();
	let text = "Velg sted";
	if (!useStaticText) {
		text = locationContext.location || "Velg sted";
	}

	return <Button className={className}
		onClick={() => locationContext.setShowLocationDialog(true)}
		text={text}
		icon={<LocationSvg />}
	/>;
}
//#endregion

//#region [Styles]

//#endregion