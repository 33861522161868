import { StandardSvgProps } from "src/components/svg/StandardSvgProps";
import { SDefaultSvg } from 'src/components/svg/SvgStyles';
import styled from "src/lib/styles/css";

//#region [Props]
type CalendarSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function CalendarSvg({ className }: CalendarSvgProps) {
	return (
		<SCalendarSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.597 17.924">
			<path d="M419.6,468.924H407.992A1.992,1.992,0,0,1,406,466.933V455.315a1.992,1.992,0,0,1,1.992-1.992h.664v.332a1.328,1.328,0,0,0,1.328,1.328h.994a1.328,1.328,0,0,0,1.328-1.328v-.332h2.987v.332a1.328,1.328,0,0,0,1.328,1.328h.994a1.328,1.328,0,0,0,1.328-1.328v-.332h.664a1.992,1.992,0,0,1,1.992,1.992v11.618a1.992,1.992,0,0,1-2,1.992Zm-9.619-11.286a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm0,3.651a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm0,3.651a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm3.317-7.3a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm0,3.651a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm0,3.651a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm3.317-7.3a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm0,3.651a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm0,3.651a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm3.317-7.3a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm0,3.651a.664.664,0,0,0-.664-.664h-1a.664.664,0,0,0-.664.664v1a.664.664,0,0,0,.664.664h1a.664.664,0,0,0,.664-.664Zm-2.655-6.971h-.332a1,1,0,0,1-1-1V452a1,1,0,0,1,1-1h.332a1,1,0,0,1,1,1v1.328a1,1,0,0,1-1,1Zm-6.634,0h-.33a1,1,0,0,1-1-1V452a1,1,0,0,1,1-1h.33a1,1,0,0,1,1,1v1.328a1,1,0,0,1-1,1Z" transform="translate(-406 -451)" />
		</SCalendarSvg>
	);
}
//#endregion

//#region [Styles]
const SCalendarSvg = styled(SDefaultSvg)`
	path {
		fill: var(--iconcolor);
	}
`;
//#endregion