import IDateGroup from "src/components/dateselector/IDateGroup";
import styled from 'src/lib/styles/css';
import { getDaysInMonth, startOfMonth, getDay, format, addDays } from "date-fns";
import DateItem from "src/components/dateselector/DateItem";
import theme from "src/components/themes/DefaultTheme";
import locale from "date-fns/locale/nb";

//#region [Props]
type DateItemsProps = {
    month?: IDateGroup;
    selectedDate: Date;
    changeDate: (date: Date) => void;
};
//#endregion

//#region [Component]
export default function DateItems({ month, selectedDate, changeDate }: DateItemsProps) {
    if (!month) return null;
    let monthLength = getDaysInMonth(month.month);
    let monthFirstDay = getMonthFirstDay(month.month);

    const items = [];
    let index = 0;
    items.push(<SEmptyItem key={index++}>M</SEmptyItem>);
    items.push(<SEmptyItem key={index++}>T</SEmptyItem>);
    items.push(<SEmptyItem key={index++}>O</SEmptyItem>);
    items.push(<SEmptyItem key={index++}>T</SEmptyItem>);
    items.push(<SEmptyItem key={index++}>F</SEmptyItem>);
    items.push(<SEmptyItem key={index++}>L</SEmptyItem>);
    items.push(<SEmptyItem key={index++}>S</SEmptyItem>);

    for (let i = 0; i < monthFirstDay; i++) {
        items.push(<SEmptyItem key={index++}></SEmptyItem>);
    }
    let currMonthDay = startOfMonth(month.month);
    for (let i = 0; i < monthLength; i++) {
        items.push(<DateItem key={format(currMonthDay, "dd.MM.yyyy", { locale })} date={currMonthDay} month={month} selectedDate={selectedDate} changeDate={changeDate} />);
        currMonthDay = addDays(currMonthDay, 1);
    }
    return <SCalendar>{items}</SCalendar>;
}
//#endregion

//#region [Other]
function getMonthFirstDay(month: Date) {
    const som = getDay(startOfMonth(month)) - 1;
    return som < 0 ? 6 : som;
}
//#endregion

//#region [Styles]
const SCalendar = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		padding: 0 140px;
	}
`;

const SEmptyItem = styled.div`
	width: ${100 / 7}%;
	text-align: center;
	font-size: 18px;
	font-weight: normal;
	position: relative;
`;
//#endregion