"use client"
import DateSelector from "src/components/dateselector/DateSelector";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { format, isPast, parse } from "date-fns";
import locale from "date-fns/locale/nb";
import { useRouter } from 'next/router';
import React, { useEffect, useState, useContext } from 'react';

// LATER: Do we reallt need the date context, or is it massive overkill?

//#region [Other]
type ProgramDatesContextType = {
	selectedDate: Date | null;
	setSelectedDate: (selectedDate: Date) => void;
	showSelectDateDialog: boolean;
	setShowSelectDateDialog: (show: boolean) => void;
	setAvailableDates: (dates: Date[] | null) => void;
	availableDates: Date[] | null;
};

const ProgramDatesContext = React.createContext<ProgramDatesContextType>({
	selectedDate: null,
	setSelectedDate: (selectedDate) => { },
	showSelectDateDialog: false,
	setShowSelectDateDialog: (show) => { },
	setAvailableDates: (dates) => { },
	availableDates: null // TEMP STORAGE

});

function getInitialDate(path: string): Date {
	let defaultDate = new Date(); // Today
	var splitPath = path.split("#");
	if (splitPath.length > 1) {
		const re = /^#?(\d{2}\.\d{2}\.\d{4})/;
		const match = re.exec(splitPath[1]);
		if (match !== null) {
			const parsedDate = parse(match[1], "dd.MM.yyyy", defaultDate);
			if (!isPast(parsedDate)) {
				defaultDate = parsedDate;
			}
		} else {
			const cookie = getCookie("tmpSelectedDate");
			if (cookie) {
				const parsedDate = parse(cookie, "dd.MM.yyyy", defaultDate);
				if (!isPast(parsedDate)) {
					defaultDate = parsedDate;
				}
			}
		}
	}
	return defaultDate;
}

//#endregion



//#region [Props]
type ProgramDatesWrapperProps = {
	children: React.ReactNode;
};
//#endregion

//#region [Component]
export default function ProgramDatesWrapper({ children }: ProgramDatesWrapperProps) {
	var router = useRouter();
	const [selectedDate, setSelectedDate] = useState(() => getInitialDate(router.asPath));
	const [showSelectDateDialog, setShowSelectDateDialog] = useState(false);
	const [availableDates, setAvailableDates] = useState<Date[] | null>(null);

	useEffect(() => {
		// save selected date in cookie to prevent having to have a global context
		if (selectedDate === null) {
			deleteCookie("tmpSelectedDate");
		} else {
			// update cookie and hash
			const formattedDate = format(selectedDate, "dd.MM.yyyy", { locale });
			setCookie("tmpSelectedDate", formattedDate); // short lived cookie
			var splitPath = router.asPath.split("#");
			let doUpdateHash = true;
			if (splitPath.length > 1) {
				if (splitPath[1] === formattedDate) {
					doUpdateHash = false;
				}
			}
			if (doUpdateHash && router.isReady) {
				router.replace("#" + formattedDate, undefined, { shallow: true, scroll: false }).catch(e => {
					// workaround https://github.com/vercel/next.js/issues/37362#issuecomment-1283671326
					if (!e.cancelled) {
						throw e;
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate]);

	return <ProgramDatesContext.Provider value={
		{
			selectedDate: selectedDate,
			setSelectedDate: setSelectedDate,
			showSelectDateDialog: showSelectDateDialog,
			setShowSelectDateDialog: setShowSelectDateDialog,
			setAvailableDates: setAvailableDates,
			availableDates: availableDates
		}
	}>
		{children}
		{showSelectDateDialog && <DateSelector/>}
	</ProgramDatesContext.Provider>;
}
//#endregion

//#region [Other]
export function useProgramDatesContext() {
	return useContext(ProgramDatesContext);
}
//#endregion