import LocationSvg from "src/components/svg/LocationSvg";
import theme from "src/components/themes/DefaultTheme";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import styled from 'src/lib/styles/css';

//#region [Props]
type NoLocationProps = {
	className?: string;
};
//#endregion

//#region [Component]
export default function NoLocation({ className }: NoLocationProps) {
	const locationContext = useLocationContext();
	return <SNoLocation
		className={className}
		title="Klikk for å velge kinosted"
		onClick={() => locationContext.setShowLocationDialog(true)}
		>
		<SLocIcon />
		<SNoLocText>Du må velge kinosted for å se visninger.</SNoLocText>
	</SNoLocation>;
}
//#endregion

//#region [Styles]
const SNoLocation = styled.div`
	background-color: var(--buttoncolor);
	color: var(--buttontextcolor);
	padding: 5px 12px;
	margin: 12px;
	border-radius: 15px;
	text-align: center;
	min-height: 321px;
	cursor: pointer;

	@media ${theme.mq.desktop} {
		margin: 15px 0px;
	}
`;

const SLocIcon = styled(LocationSvg)`
	max-width: 38px;
	margin: 0 auto;
	padding-top: 80px;
	fill: var(--buttontextcolor);
`;

const SNoLocText = styled.div`
	font-size: 16px;
	padding: 29px 34px 0;
`;
//#endregion