import Button from "src/components/button/Button";
import CalendarSvg from "src/components/svg/CalendarSvg";
import { format } from "date-fns";
import locale from "date-fns/locale/nb";
import { useProgramDatesContext } from "src/lib/contexts/ProgramDatesWrapper";

//#region [Props]
type DateSelectorButtonProps = {
	className?: string;
	useStaticText?: boolean;
};
//#endregion

//#region [Component]
export default function DateSelectorButton({ className, useStaticText = false }: DateSelectorButtonProps) {
	const selectedDateContext = useProgramDatesContext();
	let text = "Velg dato";
	if (!useStaticText) {
		text = selectedDateContext.selectedDate && selectedDateContext.availableDates?.length ? format(new Date(selectedDateContext.selectedDate), "iii d. MMM", { locale }) : "Velg dato";
	}

	return <Button className={className}
		onClick={() => selectedDateContext.setShowSelectDateDialog(true)}
		text={text}
		icon={<CalendarSvg />}
		disabled={(selectedDateContext?.availableDates?.length ?? 0) === 0}
	/>;
}
//#endregion

//#region [Styles]

//#endregion