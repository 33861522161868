import { StandardSvgProps } from "src/components/svg/StandardSvgProps";
import { SDefaultSvg } from "src/components/svg/SvgStyles";
import styled from 'src/lib/styles/css';

//#region [Props]
type RightArrowSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function RightArrowSvg({ className }: RightArrowSvgProps) {
	return <SDefaultSvg className={className} viewBox="0 0 12.12 20">
		<path d="M 0,2.12 7.88,10 0,17.88 2.12,20 12.12,10 2.12,0 Z" />
	</SDefaultSvg>;
}
//#endregion

//#region [Styles]

//#endregion