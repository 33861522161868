import ImageTitleAspect2_1 from "src/components/articledisplays/ImageTitleAspect2_1";
import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';
import IFilmnytt from "src/lib/types/Filmnytt";

//#region [Props]
type FilmwebNewsProps = {
	isStreamingContext?: boolean;
	articles?: IFilmnytt[] | null;
	padArticles?: IFilmnytt[] | null;
	className?: string;
};
//#endregion

const SIZES_THREE = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth / 3}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 33vw, 100vw`;

//#region [Component]
export default function FilmwebNews({ isStreamingContext = false, articles, padArticles, className }: FilmwebNewsProps) {
	/**
	 * For kino så hentes nyhetsartikler fra forsiden.
	 * For Streaming så hentes nyhetsartikler fra streamingfrsiden
	 */
	if ((articles?.length ?? 0) === 0 && (padArticles?.length ?? 0) === 0) {
		return null;
	}

	let arts = articles ?? [];
	if( (arts?.length ?? 0) % 3 !== 0 && (padArticles?.length ?? 0) > 0) {
		const x = 3-(arts!.length ?? 0) % 3;
		arts = arts!.concat(padArticles!.slice(0, 3-(arts!.length ?? 0) % 3));
	}

	return <SNewsWrapper className={className}>
		{arts?.map((article, idx) => <SImageTitleAspect2_1 key={`${article._id}_${idx}`}
			document={article}
			isStreamingContext={isStreamingContext}
			sizes={SIZES_THREE}/>)}
	</SNewsWrapper>;
}
//#endregion

//#region [Styles]
const SNewsWrapper = styled.aside`
	display: flex;
	gap: ${theme.maxContentPadding};
	margin: 0 ${theme.maxContentPadding};
	flex-wrap: wrap;

	@media ${theme.mq.desktop} {
		margin: 0;
		display: grid;
		gap: 35px;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: auto;
		grid-auto-rows: auto;
	}
`;

const SImageTitleAspect2_1 = styled(ImageTitleAspect2_1)`
	flex: 0 0 100%;
`;
//#endregion