import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';

//#region [Props]
type KinoklubbProps = {
	className?: string;
};
//#endregion

//#region [Component]
export default function Kinoklubb({ className }: KinoklubbProps) {
	return <SKinoklubb className={className}>
		* Halv pris på <SKinoklubbLink href="https://www.kinoklubb.no/">Kinoklubb-forestillinger</SKinoklubbLink>
	</SKinoklubb>;
}
//#endregion

//#region [Styles]
const SKinoklubb = styled.div`
	color: var(--kinoklubbcolor, ${theme.kinoklubbColor});
	text-align: center;

	@media ${theme.mq.desktop} {
		//@media(orientation: landscape) {
		text-align: left;
		padding-left: 10px;
	}
`;

const SKinoklubbLink = styled.a`
	text-decoration: underline;
	&:visited {
		color: var(--kinoklubbcolor, ${theme.kinoklubbColor});
	}

	&:link {
		color: var(--kinoklubbcolor, ${theme.kinoklubbColor});
	}
`;
//#endregion